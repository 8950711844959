/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { graphql, Link } from 'gatsby';

// UI lib components
import { Container } from 'react-grid-system';

// Page wrappers
import Layout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// Assets
import ERROR_404 from '../images/404/404.svg';

// Style
import '../page-styles/404.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function NotFoundPage() {
  /* -------------------------------- CONST --------------------------------- */
  const browser = typeof window !== 'undefined' && window;

  return (
    browser && (
      <Layout>
        <Seo title="404: Not found" />
        <Container
          className="not-found-page flex flex-column items-center justify-center fluid"
          fluid
        >
          <img src={ERROR_404} alt="404" />
          <h4>Oups, cette page est introuvable !</h4>
          <Link to="/">
            <button type="submit">Acceuil</button>
          </Link>
        </Container>
      </Layout>
    )
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
